
import { computed, ComputedRef, defineComponent } from "vue";
import { LoadingAnimationStyleInterface } from "./loading_animation_interface";
import { loadingAnimationDefaultStyle } from "./loading_animation_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
export default defineComponent({
    name: "LoadingAnimation",
    components: {},
    props: {
        /**
         * id is necessary to uniquely identify this animation
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * specifies the name of the HTML animation element
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * Size interface of the loadingAnimation, setting strokeWidth, strokeLength and radius of the animation
         */
        animationStyle: {
            type: Object as () => LoadingAnimationStyleInterface,
            default: {} as LoadingAnimationStyleInterface,
        },
    },
    setup(props) {
        const finalLoadingAnimationStyle: ComputedRef<LoadingAnimationStyleInterface> =
            computed(() => {
                return {
                    ...loadingAnimationDefaultStyle,
                    ...getSpaceConfigStyle()?.loadingAnimation,
                    ...props.animationStyle,
                };
            });

        return {
            finalLoadingAnimationStyle,
        };
    },
});
