
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { computed, ComputedRef, defineComponent } from "vue";
import { MainContainerStyleInterface } from "./main_container_interface";
import { mainContainerDefaultStyle } from "./main_container_default_style";
/**
 * slots: without name == content, append, prepend, actionButton, title
 */
export default defineComponent({
    name: "MainContainer",
    props: {
        /**
         * the title of the container, can be replaced with the slot title
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * the style of the main container
         * this has the following attributes:
         * {
         *      ! backgroundColor?: string;
         *      ! containerBackgroundColor?: string;
         *      ! containerMaxWidth?: string;
         *      ! titleColor?: string;
         * }
         */
        mainContainerStyle: {
            type: Object as () => MainContainerStyleInterface,
            default: {} as MainContainerStyleInterface,
        },
    },
    setup(props) {
        const finalMainContainerStyle: ComputedRef<MainContainerStyleInterface> =
            computed(() => {
                return {
                    ...mainContainerDefaultStyle,
                    ...getSpaceConfigStyle()?.mainContainer,
                    ...props.mainContainerStyle,
                };
            });

        return {
            finalMainContainerStyle,
        };
    },
});
