import { loadingAnimationDefaultStyle } from "@/components/helpers/loadingAnimation/loading_animation_default_style";
import { CustomButtonStyleInterface } from "./custom_button_interfaces";

export const customButtonDefaultStyle: CustomButtonStyleInterface = {
    /**
     * General Style
     */
    horizontalPadding: "4",
    verticalPadding: "1.5",
    fontSize: "md",
    ...loadingAnimationDefaultStyle,

    /**
     * plain Style
     */
    plainTextColor: "primary",
    plainDisabledTextColor: "grey-darker",

    plainBackgroundColor: "transparent", // and focus
    plainBackgroundHoverColor: "grey-lightest",
    plainBackgroundPressedColor: "grey-lighter",

    plainBorderColor: "transparent",
    plainBorderHoverColor: "transparent",
    plainBorderPressedColor: "transparent",
    plainBorderFocusColor: "primary",

    /**
     * grey Style
     */
    greyTextColor: "primary",
    greyDisabledTextColor: "grey-darker",

    greyBackgroundColor: "grey-lightest", // and focus
    greyBackgroundHoverColor: "grey-lighter",
    greyBackgroundPressedColor: "grey-light",

    greyBorderColor: "transparent",
    greyBorderHoverColor: "transparent",
    greyBorderPressedColor: "transparent",
    greyBorderFocusColor: "primary",

    /**
     * outline Style
     */
    outlineTextColor: "primary",
    outlineDisabledTextColor: "grey-darker",

    outlineBackgroundColor: "transparent", // and focus
    outlineBackgroundHoverColor: "grey-lightest",
    outlineBackgroundPressedColor: "grey-lighter",

    outlineBorderColor: "grey-lighter",
    outlineBorderHoverColor: "grey-lighter",
    outlineBorderPressedColor: "grey-lighter",
    outlineBorderFocusColor: "primary",

    /**
     * standard Style
     */
    standardTextColor: "white",
    standardDisabledTextColor: "white",

    standardBackgroundColor: "primary", // and focus
    standardBackgroundHoverColor: "primary-dark",
    standardBackgroundPressedColor: "primary-darkest",

    standardBorderColor: "transparent",
    standardBorderHoverColor: "transparent",
    standardBorderPressedColor: "transparent",
    standardBorderFocusColor: "primary",
};
