import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "loading-animation justify-center items-center flex relative",
    style: _normalizeStyle(
            'height: ' +
            _ctx.animationStyle.radius * 2 +
            'px; width: ' +
            (parseInt(_ctx.finalLoadingAnimationStyle.strokeLength) +
                parseInt(_ctx.finalLoadingAnimationStyle.radius) * 2) +
            'px;'
        )
  }, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (i) => {
      return _createElementVNode("div", {
        key: i,
        class: _normalizeClass([`bg-${_ctx.finalLoadingAnimationStyle.animationColor}`, "top-0 left-1/2 absolute block"]),
        style: _normalizeStyle(`border-radius: 30%; width: ${_ctx.finalLoadingAnimationStyle.strokeWidth}px; height: ${_ctx.finalLoadingAnimationStyle.strokeLength}px; transform-origin: 1.5px ${_ctx.finalLoadingAnimationStyle.radius}px`)
      }, null, 6)
    }), 64))
  ], 4))
}