
import LoadingAnimation from "../../helpers/loadingAnimation/LoadingAnimation.vue";
import { computed, ComputedRef, defineComponent, ref, watch } from "vue";
import { CustomButtonStyleInterface } from "./custom_button_interfaces";

import { customButtonDefaultStyle } from "./custom_button_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { useHelpersStore } from "@/store/helpers";
import { storeToRefs } from "pinia";
import { MetaInformationInterface } from "@/store/helpers/interfaces";
export default defineComponent({
  name: "CustomButton",
  components: { LoadingAnimation },
  props: {
    /**
     * ID  for unique identification
     */
    id: {
      type: String,
    },

    /**
     * Button Style Object
     */
    buttonStyle: {
      type: Object as () => CustomButtonStyleInterface,
      default: {} as CustomButtonStyleInterface,
    },

    /**
     * !isDisabled : the button can be clicked/focused
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * isPlainButton : the button dont have any backgorund color
     *
     */
    isPlainButton: {
      type: Boolean,
      default: false,
    },

    /**
     * isGreyButton : a button with grey background-color
     *
     */
    isGreyButton: {
      type: Boolean,
      default: false,
    },
    /**
     * isOutlineButton : is a plain Button with border
     *
     */
    isOutlineButton: {
      type: Boolean,
      default: false,
    },

    /**
     * Displays the load-animation
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { loadingIds } = storeToRefs(useHelpersStore());

    const finalCustomButtonStyle: ComputedRef<CustomButtonStyleInterface> =
      computed(() => {
        return {
          ...customButtonDefaultStyle,
          ...getSpaceConfigStyle()?.customButtom,
          ...props.buttonStyle,
        };
      });

    const isLoading = computed(() => {
      const hasLoadingId = loadingIds.value.find(
        (element: MetaInformationInterface) => element.id == props.id
      );
      let isLoading = false;
      if (hasLoadingId) {
        isLoading = true;
      }
      return isLoading;
    });

    //to keep the right size of the button when loading is getting triggered, we need to save the button width before changing to the loading animation

    const buttonElement = ref<HTMLButtonElement>();
    const isLoadingAndWidthSaved = ref<boolean>(false);

    function resizeButton(): void {
      if (props.isLoading && buttonElement.value) {
        const buttonWidth = buttonElement.value?.offsetWidth ?? 0;
        buttonElement.value.style.minWidth = `${buttonWidth}px`;
      }
      requestAnimationFrame(() => {
        isLoadingAndWidthSaved.value = isLoading.value;
      });
    }

    watch(
      () => isLoading.value,
      () => {
        resizeButton();
      }
    );

    resizeButton();

    return {
      finalCustomButtonStyle,
      isLoadingAndWidthSaved,
      buttonElement,
    };
  },
});
