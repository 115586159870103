import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row items-center justify-items-center h-6"
}
const _hoisted_3 = {
  key: 1,
  class: "flex w-full justify-center h-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_animation = _resolveComponent("loading-animation")!

  return (_openBlock(), _createElementBlock("button", {
    id: _ctx.id,
    class: _normalizeClass(["custom-button flex items-center justify-center rounded-md cursor-pointer transition-all border-2 border-transparent active:scale-95 transform", [
      `pr-${_ctx.finalCustomButtonStyle.horizontalPadding} pl-${
        _ctx.$slots.previous != null ? '2' : _ctx.finalCustomButtonStyle.horizontalPadding
      } 
      py-${_ctx.finalCustomButtonStyle.verticalPadding}`,
      _ctx.isDisabled ? `opacity-80 pointer-events-none` : '',
      _ctx.isLoading ? 'pointer-events-none' : '',
      _ctx.isPlainButton
        ? `bg-${_ctx.finalCustomButtonStyle.plainBackgroundColor} 
        hover:bg-${_ctx.finalCustomButtonStyle.plainBackgroundHoverColor}
        active:bg-${_ctx.finalCustomButtonStyle.plainBackgroundPressedColor}
        focus:bg-${_ctx.finalCustomButtonStyle.plainBackgroundColor}
        border-${_ctx.finalCustomButtonStyle.plainBorderColor} 
        hover:border-${_ctx.finalCustomButtonStyle.plainBorderHoverColor}
        active:border-${_ctx.finalCustomButtonStyle.plainBorderPressedColor}
        focus:border-${_ctx.finalCustomButtonStyle.plainBorderFocusColor}`
        : _ctx.isGreyButton
        ? `bg-${_ctx.finalCustomButtonStyle.greyBackgroundColor} 
        hover:bg-${_ctx.finalCustomButtonStyle.greyBackgroundHoverColor}
        active:bg-${_ctx.finalCustomButtonStyle.greyBackgroundPressedColor}
        focus:bg-${_ctx.finalCustomButtonStyle.greyBackgroundColor}
        border-${_ctx.finalCustomButtonStyle.greyBorderColor} 
        hover:border-${_ctx.finalCustomButtonStyle.greyBorderHoverColor}
        active:border-${_ctx.finalCustomButtonStyle.greyBorderPressedColor}
        focus:border-${_ctx.finalCustomButtonStyle.greyBorderFocusColor}`
        : _ctx.isOutlineButton
        ? `bg-${_ctx.finalCustomButtonStyle.outlineBackgroundColor} 
        hover:bg-${_ctx.finalCustomButtonStyle.outlineBackgroundHoverColor}
        active:bg-${_ctx.finalCustomButtonStyle.outlineBackgroundPressedColor}
        focus:bg-${_ctx.finalCustomButtonStyle.outlineBackgroundColor}
        border-${_ctx.finalCustomButtonStyle.outlineBorderColor} 
        hover:border-${_ctx.finalCustomButtonStyle.outlineBorderHoverColor}
        active:border-${_ctx.finalCustomButtonStyle.outlineBorderPressedColor}
        focus:border-${_ctx.finalCustomButtonStyle.outlineBorderFocusColor}`
        : `bg-${_ctx.finalCustomButtonStyle.standardBackgroundColor} 
        hover:bg-${_ctx.finalCustomButtonStyle.standardBackgroundHoverColor}
        active:bg-${_ctx.finalCustomButtonStyle.standardBackgroundPressedColor}
        focus:bg-${_ctx.finalCustomButtonStyle.standardBackgroundColor}
        border-${_ctx.finalCustomButtonStyle.standardBorderColor} 
        hover:border-${_ctx.finalCustomButtonStyle.standardBorderHoverColor}
        active:border-${_ctx.finalCustomButtonStyle.standardBorderPressedColor}
        focus:border-${_ctx.finalCustomButtonStyle.standardBorderFocusColor}`,
    ]]),
    ref: "buttonElement"
  }, [
    (!_ctx.isLoadingAndWidthSaved)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "previous", {
            color: 
          _ctx.isPlainButton
            ? `${
                _ctx.isDisabled
                  ? _ctx.finalCustomButtonStyle.plainDisabledTextColor
                  : _ctx.finalCustomButtonStyle.plainTextColor
              }`
            : _ctx.isGreyButton
            ? `${
                _ctx.isDisabled
                  ? _ctx.finalCustomButtonStyle.greyDisabledTextColor
                  : _ctx.finalCustomButtonStyle.greyTextColor
              }`
            : _ctx.isOutlineButton
            ? `${
                _ctx.isDisabled
                  ? _ctx.finalCustomButtonStyle.outlineDisabledTextColor
                  : _ctx.finalCustomButtonStyle.outlineTextColor
              }`
            : `${
                _ctx.isDisabled
                  ? _ctx.finalCustomButtonStyle.standardDisabledTextColor
                  : _ctx.finalCustomButtonStyle.standardTextColor
              }`
        
          }),
          _createElementVNode("h3", {
            class: _normalizeClass(["font-medium transition-color max-w-full truncate", [
          _ctx.$slots.previous != null ? 'ml-2' : '',
          `text-${_ctx.finalCustomButtonStyle.fontSize}`,
          _ctx.isPlainButton
            ? `text-${
                _ctx.isDisabled
                  ? _ctx.finalCustomButtonStyle.plainDisabledTextColor
                  : _ctx.finalCustomButtonStyle.plainTextColor
              }`
            : _ctx.isGreyButton
            ? `text-${
                _ctx.isDisabled
                  ? _ctx.finalCustomButtonStyle.greyDisabledTextColor
                  : _ctx.finalCustomButtonStyle.greyTextColor
              }`
            : _ctx.isOutlineButton
            ? `text-${
                _ctx.isDisabled
                  ? _ctx.finalCustomButtonStyle.outlineDisabledTextColor
                  : _ctx.finalCustomButtonStyle.outlineTextColor
              }`
            : `text-${
                _ctx.isDisabled
                  ? _ctx.finalCustomButtonStyle.standardDisabledTextColor
                  : _ctx.finalCustomButtonStyle.standardTextColor
              }`,
        ]])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_loading_animation, {
            id: _ctx.id + '-loading',
            name: _ctx.id + '-loading',
            animationStyle: {
          ..._ctx.buttonStyle,
          animationColor: _ctx.isPlainButton
            ? _ctx.finalCustomButtonStyle.plainTextColor
            : _ctx.isGreyButton
            ? _ctx.finalCustomButtonStyle.greyTextColor
            : _ctx.isOutlineButton
            ? _ctx.finalCustomButtonStyle.outlineTextColor
            : _ctx.finalCustomButtonStyle.standardTextColor,
        }
          }, null, 8, ["id", "name", "animationStyle"])
        ]))
  ], 10, _hoisted_1))
}